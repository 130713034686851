import { makeAutoObservable } from 'mobx';
import { ListingType } from '@Encore-FastStartup-Phase-Out/encore-shared';

import MixpanelService from '../services/MixpanelService';
import { CompleteListing } from '../models/Listing';

export class UIStore {
  isDownloadPopupOpen: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  openDownloadPopup() {
    this.isDownloadPopupOpen = true;
  }

  closeDownloadPopup() {
    this.isDownloadPopupOpen = false;
  }

  clickOnCustom(listing: CompleteListing) {
    this.openDownloadPopup();
    if (listing.listingType === ListingType.AUCTION) {
      MixpanelService.trackCustomBidClick(
        listing.title ? listing.title : listing.id,
      );
    } else {
      MixpanelService.trackMakeOfferClick(
        listing.title ? listing.title : listing.id,
      );
    }
  }

  clickOnPrimaryButton(listing: CompleteListing) {
    this.openDownloadPopup();
    const mixpanelActions = {
      [ListingType.GIVEAWAY]: () =>
        MixpanelService.trackEnterGiveawayClick(listing.id),
      [ListingType.AUCTION]: () => MixpanelService.trackBidSwipe(listing.id),
      [ListingType.DEFAULT]: () =>
        MixpanelService.trackBuyItNowClick(listing.id),
    };

    mixpanelActions[listing.listingType]?.();
  }
}
