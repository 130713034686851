import mixpanel from 'mixpanel-browser';

class MixpanelService {
  private static instance: MixpanelService;
  private static initialized = false;

  private constructor() {
    const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;
    if (!mixpanelToken) {
      throw new Error('Mixpanel token is not provided');
    }
    mixpanel.init(mixpanelToken);
    MixpanelService.initialized = true;
  }

  public static getInstance(): MixpanelService {
    if (!MixpanelService.instance) {
      MixpanelService.instance = new MixpanelService();
    }
    return MixpanelService.instance;
  }

  private getDefaultProperties() {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
    const isAndroid = /Android/i.test(userAgent);

    return {
      browser: this.getBrowserName(userAgent),
      device: isMobile
        ? isIOS
          ? 'iOS'
          : isAndroid
            ? 'Android'
            : 'Mobile'
        : 'Desktop',
      screenWidth: window.screen.width,
      screenHeight: window.screen.height,
      platform: this.getOS(userAgent),
      time: new Date().toISOString(),
    };
  }

  private getBrowserName(userAgent: string) {
    if (/Chrome/i.test(userAgent) && !/Edg/i.test(userAgent)) return 'Chrome';
    if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent))
      return 'Safari';
    if (/Firefox/i.test(userAgent)) return 'Firefox';
    if (/Edg/i.test(userAgent)) return 'Edge';
    if (/OPR/i.test(userAgent) || /Opera/i.test(userAgent)) return 'Opera';
    return 'Unknown';
  }

  private getOS(userAgent: string) {
    if (/Windows NT/i.test(userAgent)) return 'Windows';
    if (/Mac OS X/i.test(userAgent)) return 'macOS';
    if (/Linux/i.test(userAgent)) return 'Linux';
    if (/Android/i.test(userAgent)) return 'Android';
    if (/iPhone|iPad|iPod/i.test(userAgent)) return 'iOS';
    return 'Unknown';
  }

  public track(event: string, properties?: object): void {
    if (!MixpanelService.initialized) {
      throw new Error('Mixpanel is not initialized');
    }

    const eventProperties = {
      ...this.getDefaultProperties(),
      ...properties,
    };

    mixpanel.track(event, eventProperties);
  }

  public trackSearch(pageName: string, searchQuery: string): void {
    this.track('Search Click', {
      filter: searchQuery,
      tab: pageName,
    });
  }

  public clickedOnBuyItNowListing(listingId: string) {
    this.track('Clicked on Buy it Now Listing', { listingId });
  }

  public clickedOnGiveawayListing(listingId: string) {
    this.track('Clicked on Giveaway Listing', { listingId });
  }

  public clickedOnAuction(listingId: string) {
    this.track('Clicked on Auction', { listingId });
  }

  public clickedOnPage(pageName: string) {
    this.track('Clicked on Page', { pageName });
  }

  public clickedOnCategoryViewAll(category: string) {
    this.track('Clicked on Category View All', { category });
  }

  public trackExitFromListing(listingId: string) {
    this.track('Exit from Listing', { listingId });
  }

  public trackProfileClickFromListing(tabName: string, sellerId: string) {
    this.track('Clicked on Profile from Listing', { tab: tabName, sellerId });
  }

  public trackFollowFromListing(tabName: string, sellerId: string) {
    this.track('Followed profile from listing', { tab: tabName, sellerId });
  }

  public trackFollowFromProfile(profileId: string) {
    this.track('Followed profile from profile', { profileId });
  }

  public trackCustomBidClick(listingTitle: string) {
    this.track('Clicked on custom bid', {
      listingTitle,
    });
  }

  public trackBidSwipe(listingId: string) {
    this.track('Swiped to bid', {
      listingId,
    });
  }

  public trackEnterGiveawayClick(listingId: string) {
    this.track('Clicked on Enter Giveaway', { listingId });
  }

  public trackMakeOfferClick(listingId: string) {
    this.track('Clicked Make Offer on listing', { listingId });
  }

  public trackBuyItNowClick(listingId: string) {
    this.track('Clicked Buy it Now on listing', { listingId });
  }

  public trackMessageSellerClick(sellerId: string) {
    this.track('Clicked Message Seller on profile', { sellerId });
  }

  public downloadAppPopUpShown() {
    this.track('Download App Pop Up Shown');
  }

  public trackClickOnOpenAppMobile() {
    this.track('Clicked on Open App Mobile');
  }

  public trackClickExtraEntries(listingId: string) {
    this.track('Clicked on Extra Entries', { listingId });
  }
}

export default MixpanelService.getInstance();
